import "./header.css"

import React from "react"
import firebase from "gatsby-plugin-firebase"
import logo from "../images/logo.jpeg"
import { navigate } from "gatsby"

class Header extends React.Component {
  constructor() {
    super()

    this.state = {
      email: "",
      loggedIn: false,
    }

    this.logOutUser = this.logOutUser.bind(this)
  }

  componentDidMount() {
    console.log("running")
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log("Logged In " + user.email)
        this.setState({ email: user.email })
        this.setState({ loggedIn: true })
        // navigate("/transapp");
      } else {
        this.setState({ loggedIn: false })
      }
    })
  }

  logOutUser() {
    firebase
      .auth()
      .signOut()
      .then(
        function() {
          // console.log("Logged out!");
          navigate("/")
        },
        function(error) {
          //console.log(error.code);
          //console.log(error.message);
        }
      )
  }

  render() {
    if (this.state.loggedIn === true) {
      return (
        <div className="HeaderComp">
          <img className="HeaderLogo" src={logo} />

          <h3 className="HeaderText">
            Welcome! <br />
            <br />
            {this.state.email}
          </h3>
          <button className="HeaderButton" onClick={this.logOutUser}>
            Close Session
          </button>
        </div>
      )
    } else if (this.state.loggedIn === false) {
      return (
        <div className="HeaderComp">
          <img className="HeaderLogo" src={logo} />

          <h1 className="HeaderText">
            Your Transformation Conversation <br />
            <br />
            {this.state.email}
          </h1>
        </div>
      )
    } else {
      return (
        <div className="HeaderComp">
          <img className="HeaderLogo" src={logo} />

          <h3 className="HeaderText">
            Welcome! <br />
            <br />
            {this.state.email}
          </h3>
        </div>
      )
    }
  }
}

export default Header
